<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`"> 
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-9' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <div class="flex items-center">
              <span>ASIN Sold Count</span>
            </div>
            <div class="flex items-center">
              <div class="">
                <button @click="exportReport()" 
                :class="`
                  ${firstLoading && reportListing.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'}
                  ${isFilterLock ? 'mr-0' : 'mr-10'}
                  `"
                  style="letter-spacing: 1px;" class="p-1 px-2 cust_card heading-6 rounded flex items-center">
                  Export to Excel
                </button>
              </div>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" v-if="!isFilterLock">
              <button id="toggle" class="bg-gray3 hover:bg-gray4 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div>
            <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder relative mt-2 bg-white" v-if="firstLoading">
              <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;z-index: 10;">
                <div class="xsmall_col centerLine p-2 text-center sticky left-0 bg-white" style="height: 36px !important;">
                  <span class="">Index</span>
                </div>
                <div class="mid_col flex items-center centerLine sticky bg-white h-9" style="left: 54px !important;">
                  <span class="cursor-pointer"  @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="Xlarge_col flex items-center centerLine ">
                  <span class="cursor-pointer"  @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="smallA_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="val1_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText ">
                  <span class="cursor-pointer"  @click="sortByColumn(8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(12, titleObj[12].sort, titleObj[12].value)">{{titleObj[12].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(13, titleObj[13].sort, titleObj[13].value)">{{titleObj[13].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(14, titleObj[14].sort, titleObj[14].value)">{{titleObj[14].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="val1_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(15, titleObj[15].sort, titleObj[15].value)">{{titleObj[15].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
                <div class="small_col flex items-center rightText">
                  <span class="cursor-pointer"  @click="sortByColumn(16, titleObj[16].sort, titleObj[16].value)">{{titleObj[16].title}}</span>
                  <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                  <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
                </div>
              </div>
              <div :style="`max-height:` +  cardHight + `px; !important`" class="">
                <div class="min-w-min group flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1  hover:bg-blue-50" :class="{'bg-gray-50': indexA % 2}" v-for="(value, indexA) in reportListing" :key="indexA">
                  <div class="xsmall_col sticky left-0 bg-white text-center group-hover:bg-blue-50  flex items-center justify-center" style="height: 76px !important" :class="{'bg-gray-50': indexA % 2}">
                    <span class="heading-6-1 text-text1">{{indexA + 1}}</span>
                  </div>
                  <div class="mid_col cursor-pointer text-text1 sticky bg-white group-hover:bg-blue-50 flex items-center" style="height: 76px !important;left: 54px !important;" :class="{'bg-gray-50': indexA % 2}">
                    <div class="w-16">
                      <img v-if="value.asinImgThumbnail !== ''" class="border border-gray-100 rounded-xl bg-white" style="height: 42px;width:90px;object-fit: contain;" :src="value.asinImgThumbnail">
                      <img v-else class="border border-gray-100 rounded-xl px-2 bg-white" style="height: 40px;object-fit: contain;" src="@/assets/default.png">
                    </div>
                    <span class="heading-6-1 pl-2" v-if="value.asin !== ''">{{value.asin}}</span>
                    <span class="heading-6-1 pl-2" v-else>--</span>
                  </div>
                  <div class="Xlarge_col">
                    <span class="heading-6-1 text-text1">
                      <span class="mb-0 whitespace-pre-line">
                        {{value.description === '' ? '--' : (value.description.length > 140)? value.isDescViewM ?  value.description : value.description.substr(0, 140) : value.description}}
                        <span v-if="value.description.length > 140" class="text-primary cursor-pointer heading-7" @click="value.isDescViewM = !value.isDescViewM">
                          {{value.isDescViewM === false ? 'Read More...' : 'Read Less...'}}</span>
                      </span>
                    </span>
                  </div>
                  <div class="smallA_col rightText">
                    <span class="heading-6-1 text-text1" v-if="value.minProductSalePrice !== value.maxProductSalePrice">{{value.minProductSalePrice | amountFormaterWithToFix}} - {{value.maxProductSalePrice | amountFormaterWithToFix}}</span>
                    <span class="heading-6-1 text-text1" v-if="value.minProductSalePrice === value.maxProductSalePrice">{{value.maxProductSalePrice | amountFormaterWithToFix}}</span>
                  </div>
                  <div class="val1_col rightText">
                    <span class="heading-6-1 text-text1">{{value.fbaInvTotalQuantity}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldYesterday}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast7Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast30Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldThisMonth}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLastMonth}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast60Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast90Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast120Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast180Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldLast365Days}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldMonthToDate}}</span>
                  </div>
                  <div class="val1_col rightText">
                    <span class="heading-6-1 text-text1">{{value.soldYearToDate}}</span>
                  </div>
                  <div class="small_col rightText">
                    <span class="heading-6-1 text-text1" v-if="value.fbaInvLastUpdatedTime !== ''">{{value.fbaInvLastUpdatedTime | formatDataeTime}}</span>
                    <span class="heading-6-1 text-text1" v-else>--</span>
                  </div>
                </div>
              </div>
              <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
              <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="box col-span-3 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-gray3 p-2 items-center justify-between flex h-11 sticky top-0" :class="isFilterLock ? 'rounded-md' : ''" >
              <div class="flex items-center">
                <div class="pr-3 heading-5 text-white cursor-pointer" v-if="!isFilterLock" @click="lockBtn()"><i class="fa-solid fa-unlock"></i></div>
                <span class="pr-3 heading-5 text-white cursor-pointer" v-if="isFilterLock" @click="lockBtn()"><i class="fa-solid fa-lock"></i></span>
                <div class="heading-4 text-white font-bold">Filter</div>
              </div>
              <div class=" text-white cursor-pointer hover:underline" @click="applyFilter(false)">
                <button style="letter-spacing: 1px;" class="border border-primary bg-primary px-3 py-1 cust_card heading-7">Apply</button>
              </div>
            </div> 
            <div class="pt-1">
              <!-- <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div> -->
            </div>
            <div class="p-2 pt-1" >
              <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-400 rounded-md px-2 py-1 items-center h-8 mt-1" >
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Search...." id="custSearchInput"
                  v-model="searchVal"
                >
                <span v-if="searchVal.length > 0" class="cursor-pointer"  @click="clearSearch()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div>
                <div class="pr-2 pt-2 pb-1 flex" v-if="!isShowData">
                  <button @click="showConditionWiseData('ASIN')" style="letter-spacing: 0.5px;" class="bg-primary text-white rounded-md px-2 py-1 heading-7 ">Show all ASIN Data</button>
                </div>
                <div v-if="isShowData">
                  <div class="flex justify-center mt-3" v-if="searchLoader">
                    <span class="loader2 flex ml-1"></span>
                  </div>
                  <div class="flex  px-2 py-2 items-center justify-between" v-if="itemListing.length > 0">
                    <div class="flex items-center cursor-pointer" @click="selectAll()" >
                      <input type="checkbox" class="w-4 h-4" v-model="selectAllCheck">
                      <div class="text-text1 pl-1 heading-6">
                        {{!selectAllCheck ? 'Select All' : 'Deselect All'}}
                      </div>
                    </div>
                    <div class="text-text1 flex items-center">
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length > 0">({{dataBase.length}} Selected)</div>
                      <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0 && selectAllCheck">({{itemListing.length}} Selected)</div> -->
                      <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="dataBase.length === 0">(Total {{itemListing.length}} ASINs)</div>
                      <button  @click="resetAll()" style="letter-spacing: 1px;" class="border  cursor-pointer  border-gray-400 rounded-md px-2 py-0.5 heading-7">Clear</button>
                    </div>
                  </div>
                  <div class="overflow-auto" style="height: 560px !important;" v-if="itemListing.length > 0">
                    <div v-for="(data, index) in itemListing" :key="index">
                      <div class="flex items-start cust_card py-2 hover:bg-gray-50 px-2" v-if="data.iC !== ''">
                        <input type="checkbox" class="h-4 w-4 cursor-pointer mt-1" v-model="data.iSe" @click="selectData(data, index)">
                        <div class="pl-1 w-full cursor-pointer flex justify-between" @click="selectData(data, index)">
                          <p class=" text-text1 pl-1 heading-6 ">{{data.iC}}</p>
                          <!-- <p class=" text-primary2 pl-1 heading-6 " @click="openDetails(data)">{{data.productList.length}} Items</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="layout" v-if="itemListing.length === 0 && isRefresh">
                    <div class="flex p-2 text-sm text-error border border-error rounded items-center" role="alert">
                      <div class="flex items-center">
                        <i class="fa-solid fa-circle-exclamation heading-4"></i>
                        <p class=" font-medium heading-5 pl-2">No record found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- <div>
    <zeroRowData></zeroRowData>
  </div> -->
  </div>
</template>
<script>
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  mixins: [deboucneMixin],
  components: {
  },
  data () {
    return {
      dateReq: {
        yesterday1: '',
        yesterday2: '',
        last7Days1: '',
        last7Days2: '',
        thisMonth1: '',
        thisMonth2: '',
        lastMonth1: '',
        lastMonth2: '',
        last30Days1: '',
        last30Days2: '',
        last60Days1: '',
        last60Days2: '',
        last90Days1: '',
        last90Days2: '',
        last120Days1: '',
        last120Days2: '',
        last180Days1: '',
        last180Days2: '',
        last365Days1: '',
        last365Days2: '',
        monthToDate1: '',
        monthToDate2: '',
        yearToDate1: '',
        yearToDate2: '',
      },
      titleObj: [
        {title: 'ASIN', icon: '', value: 'asin', sort: true},
        {title: 'Amazon Desc.', icon: '', value: 'description', sort: true},
        {title: 'Min-Max Selling Price', icon: '', value: 'minProductSalePrice', sort: true},
        {title: 'FBA Qty.', icon: '', value: 'fbaInvTotalQuantity', sort: true},
        {title: 'Sold in Yesterday', icon: '', value: 'soldYesterday', sort: true},
        {title: 'Sold in last 7 days', icon: '', value: 'soldLast7Days', sort: true},
        {title: 'Sold in last 30 days', icon: '', value: 'soldLast30Days', sort: true},
        {title: 'Sold in This Month', icon: '', value: 'soldThisMonth', sort: true},
        {title: 'Sold in Last Month', icon: '', value: 'soldLastMonth', sort: true},
        {title: 'Sold in last 60 days', icon: '', value: 'soldLast60Days', sort: true},
        {title: 'Sold in last 90 Days', icon: '', value: 'soldLast90Days', sort: true},
        {title: 'Sold in last 120 Days', icon: '', value: 'soldLast90Days', sort: true},
        {title: 'Sold in last 180 Days', icon: '', value: 'soldLast180Days', sort: true},
        {title: 'Sold in last 365 days', icon: '', value: 'soldLast365Days', sort: true},
        {title: 'Month to Date', icon: '', value: 'soldMonthToDate', sort: true},
        {title: 'Year to Date', icon: '', value: 'soldYearToDate', sort: true},
        {title: 'Last Updated Time', icon: '', value: 'fbaInvLastUpdatedTime', sort: true},
      ],
      pagination: {
        sortBy: '',
        descending: true
      },
      cardHight: 0,
      mainHeight: 0,
      showFBAInventory: false,
      openZeroDetail: false,
      isShowData: false,
      isFilterLock: true,
      showAllSkudata: false,
      showAllASINdata: false,
      showAllSAGEdata: false,
      showAllRawdata: false,
      fromApiStartDate: '',
      fromApiEndDate: '',
      tabs: [
        {name: 'SAGE Item Code', active: true},
        {name: 'SKU', active: false},
        {name: 'ASIN', active: false},
        {name: 'Raw', active: false},
      ],
      isShowAllFilter: false,
      dateDiffCount: '',
      isFilterOpen: true,
      isRefresh: true,
      selectedArr: [],
      openDetail: false,
      searchVal: '',
      searchLoader: false,
      reportListing: [],
      selectAllCheck: true,
      itemListing: [],
      requiredDataListing: [],
      isSelectCustom: false,
      selectedValue: 'last30Days',
      selectedValueinText: 'Last 30 Days',
      buttonFiltersArray: [
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'last7Days'
        },
        {
          title: 'This Month',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Last 180 Days',
          selected: false,
          value: 'last180Days'
        },
        {
          title: 'Last 365 Days',
          selected: false,
          value: 'lastYear'
        },
        {
          title: 'Month to Date',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Year to Date',
          selected: false,
          value: 'thisYear'
        }
      ],
      drag: false,
      tempArrA: [],
      filterObject: {
        startDate: '',
        endDate: '',
        sendItemId: [],
        minDate: '',
        maxDate: '',
      },
      dataBase: [],
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
      getListingDetail: () => {},
      totalCountOfQuntySold: 0,
      totalFBAQty: 0,
      totalCountOfTotalNet: 0,
      adsCostOfTotalAdCostColumn: 0,
      advertisingCostInPercentage: 0,
      totalSalesOfTotalSalesColumn: 0,
      isQueryAvailable: false,
    }
  },
  created() {
  },
  beforeMount () {
    this.getRequriedDateObject()
  },
  mounted () {
      this.getData()
      console.log('this.cardHight', this.cardHight, this.mainHeight)
      document.title = 'COGS ◾ Amazon Sage-ASIN Sales Count'
      this.cardHight = window.innerHeight - 211
      this.mainHeight = window.innerHeight - 90
      this.getListingDetail = this.debounce(function () {
        this.selectAllCheck = false
        if (this.searchVal !== '') {
          this.isShowData = true
        }
        this.getData()
      }, 500)
      setTimeout(() => {
        this.getListing()
      }, 800);
  },
  watch: {
    dataBase: {
      handler () {
        console.log('Total Selected Items', this.dataBase.length)
        this.filterObject.sendItemId = []
        this.dataBase.forEach(a => {
          if (a.iC !== '') {
            this.filterObject.sendItemId.push(a)
          }
        })
      },
      deep: true
    },
    selectAllCheck: {
      handler () {
        console.log('selectAllCheck', this.selectAllCheck)
      },
      deep: true
    },
    searchVal: {
      handler() {
        this.getListingDetail()
      }
    },
  },
  methods: {
    lockBtn () {
      this.isFilterLock = !this.isFilterLock
    },
    showConditionWiseData () {
      this.isShowData = true
    },
    selectAll () {
      if (this.selectAllCheck === true) {
        this.selectAllCheck = false
      } else {
        this.selectAllCheck = true
      }
      if (this.searchVal === '') {
        if (this.selectAllCheck) {
          this.dataBase = []
          for (let index = 0; index < this.itemListing.length; index++) {
            this.itemListing[index].iSe = true
          }
        } else if (this.selectAllCheck === false) {
          this.dataBase = []
          for (let index = 0; index < this.itemListing.length; index++) {
            this.itemListing[index].iSe = false
            this.dataBase = []
          }
        }
      } else if (this.searchVal !== '') {
        if (this.selectAllCheck) {
          // this.dataBase = []
          for (let index = 0; index < this.itemListing.length; index++) {
            if (this.itemListing[index].iSe === false) {
              this.itemListing[index].iSe = true
              this.dataBase.push(this.itemListing[index])
            }
          }
        } else if (this.selectAllCheck === false) {
          this.dataBase = []
          let count = 0
          let tempArr = []
          for (let index = 0; index < this.itemListing.length; index++) {
            if (this.itemListing[index].iSe === true) {
              count ++
              tempArr.push(this.itemListing[index])
            }
          }
          console.log('count', count, tempArr)
          const intersection = this.itemListing.filter(element => tempArr.includes(element))
          const intersection2 = intersection.filter(element => !this.itemListing.includes(element))
          this.dataBase = intersection2
          this.itemListing.forEach(element1 => {
            intersection2.forEach(element2 => {
              if (element1 === element2) {
                element1.iSe = true
              }
            })
          })
          for (let J = 0; J < this.itemListing.length; J++) {
             this.itemListing[J].iSe = false
          }
        }
      }
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
      console.log('openCloseFilter')
    },
    selectData (data, index) {
      if (!this.selectAllCheck) {
        if (!data.iSe) {
          this.dataBase.push(data)
          data.iSe = true
        } else {
          const findex = this.dataBase.findIndex(x => x.iC === data.iC)
          this.itemListing[index].iSe = false
          this.dataBase.splice(findex, 1)
        }
      } else if (this.searchVal !== '' && this.selectAllCheck) {
        if (this.selectAllCheck && data.iSe) {
          this.selectAllCheck = false
          console.log('stage 7')
          this.dataBase = []
          for (let Ind = 0; Ind < this.itemListing.length; Ind++) {
            if (this.itemListing[Ind].iC === data.iC) {
              this.itemListing[Ind].iSe = false
            }
          }
          for (let J = 0; J < this.itemListing.length; J++) {
            if (this.itemListing[J].iSe) {
              this.dataBase.push(this.itemListing[J])
            }
          }
        } else {
          console.log('stage 8', this.itemListing)
          data.iSe = true
          const tempObj = this.itemListing.filter(element => !this.dataBase.includes(element))
          for (let I = 0; I < tempObj.length; I++) {
            if (tempObj[I].iSe === true) {
              this.dataBase.push(tempObj[I])
            }
            this.selectAllCheck = true   
          }
        }
      } else if (this.searchVal === '' && this.selectAllCheck && data.iSe) {
        console.log('stage 13')
        this.dataBase = []
        for (let Ind = 0; Ind < this.itemListing.length; Ind++) {
          if (this.itemListing[Ind].iC === data.iC) {
            this.itemListing[Ind].iSe = false
          }
        }
        for (let J = 0; J < this.itemListing.length; J++) {
          if (this.itemListing[J].iSe) {
            this.dataBase.push(this.itemListing[J])
          }
        }
        this.selectAllCheck = false
        data.iSe = false
      } else if (this.searchVal === '' && this.selectAllCheck && !data.iSe) {
        console.log('stage 14')
        data.iSe = true
        this.dataBase.push(data)
      }
      if (this.itemListing.length === this.dataBase.length) {
        this.selectAllCheck = true
      }
    },
    applyFilter (value) {
      if (!this.isFilterLock) {
        this.isFilterOpen = false
      }
      this.filterObject.sendItemId = []
      for (let II = 0; II < this.dataBase.length; II++) {
        for (let JJ = 0; JJ < this.dataBase[II].productList.length; JJ++) {
          if (this.dataBase[II].productList[JJ].productId !== 0) {
            this.filterObject.sendItemId.push(this.dataBase[II].productList[JJ].productId)
          }
        }
      } 
      this.getListing(value)
    },
    resetAll () {
      this.filterObject.sendItemId = []
      this.dataBase = []
      this.selectAllCheck = false
      for (let index = 0; index < this.itemListing.length; index++) {
        this.itemListing[index].iSe = false
      }
    },
    getData () {
      this.itemListing = []
      this.searchLoader = true
      this.isRefresh = false
      API.GetASINSearchListing(
        this.searchVal,
        response => {
          this.isRefresh = true
          this.itemListing = response.Data === null ? [] : response.Data
          if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
            for (let I = 0; I < this.itemListing.length; I++) {
              const findex = this.dataBase.findIndex(x => x.iC === this.itemListing[I].iC)
              console.log('findex', findex)
              if (findex > -1) {
                this.itemListing[I].iSe = true
              }
            }
          } else if (this.searchVal === '' && this.dataBase.length === 0) {
            this.selectAllCheck = true
            for (let I = 0; I < this.itemListing.length; I++) {
              this.itemListing[I].iSe = true
            }
          } else {
            this.selectAllCheck = false
          }
          this.searchLoader = false
          // if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
          //   for (let I = 0; I < this.itemListing.length; I++) {
          //     for (let J = 0; J < this.dataBase.length; J++) {
          //       if (this.dataBase[J].iC === this.itemListing[I].iC) {
          //         this.itemListing[I].iSe = true
          //       }
          //     }
          //   }
          // }
          // this.getListing()
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    async getRequriedDateObject() {
      const promises = this.buttonFiltersArray.map(async (button) => {
        const val = await getDayAccordingToLimits(button.value);
        const startDate = moment(new Date(val.startDate)).format("YYYY-MM-DD HH:mm:ss");
        const endDate = moment(new Date(val.endDate)).format("YYYY-MM-DD HH:mm:ss");

        switch (button.title) {
          case 'Yesterday':
            this.dateReq.yesterday1 = startDate;
            this.dateReq.yesterday2 = endDate;
            break;
          case 'Last 7 Days':
            this.dateReq.last7Days1 = startDate;
            this.dateReq.last7Days2 = endDate;
            break;
          case 'This Month':
            this.dateReq.thisMonth1 = startDate;
            this.dateReq.thisMonth2 = endDate;
            break;
          case 'Last Month':
            this.dateReq.lastMonth1 = startDate;
            this.dateReq.lastMonth2 = endDate;
            break;
          case 'Last 30 Days':
            this.dateReq.last30Days1 = startDate;
            this.dateReq.last30Days2 = endDate;
            break;
          case 'Last 60 Days':
            this.dateReq.last60Days1 = startDate;
            this.dateReq.last60Days2 = endDate;
            break;
          case 'Last 90 Days':
            this.dateReq.last90Days1 = startDate;
            this.dateReq.last90Days2 = endDate;
            break;
          case 'Last 120 Days':
            this.dateReq.last120Days1 = startDate;
            this.dateReq.last120Days2 = endDate;
            break;
          case 'Last 180 Days':
            this.dateReq.last180Days1 = startDate;
            this.dateReq.last180Days2 = endDate;
            break;
          case 'Last 365 Days':
            this.dateReq.last365Days1 = startDate;
            this.dateReq.last365Days2 = endDate;
            break;
          case 'Month to Date':
            this.dateReq.monthToDate1 = startDate;
            this.dateReq.monthToDate2 = endDate;
            break;
          case 'Year to Date':
            this.dateReq.yearToDate1 = startDate;
            this.dateReq.yearToDate2 = endDate;
            break;
          default:
            break;
        }
      });
      await Promise.all(promises);
    },
    getListing () {
      this.firstLoading = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      if (this.filterObject.sendItemId.length === 0) {
        this.filterObject.sendItemId = []
      }
      let TempArr = []
      API.ASINSoldCountReport(
        this.filterObject.sendItemId,
        this.dateReq,
        false,
        response => {
          TempArr = response.Data === null ? [] : response.Data
          this.reportListing = TempArr
          this.firstLoading = true
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    async exportReport () {
      if (this.firstLoading && this.reportListing.length > 0) {
        if (this.filterObject.sendItemId.length === 0) {
          this.filterObject.sendItemId = []
        }
        let Time = ''
        let subURL = ''
        subURL = 'api/v1/analytics/amazon/asinSoldCount'
        Time = 'AmazonSage Report-ASIN Sold Count'
        let data = new FormData()
        data.append('productIds', this.filterObject.sendItemId)
        data.append('dateReq', JSON.stringify(this.dateReq))
        data.append('export', true)
        await this.downloadAttachment(subURL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      this.$store.dispatch('SetDownloadLoader', true)
      let response = await downloadFile(formData, url, source)
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    clearSearch () {
      this.searchVal = ''
      this.getListingDetail()
    },
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.reportListing.sort(function (a, b) {
          if (val2 === 'asin') {
            var nameA = a.asin
            var nameB = b.asin
          } else if (val2 === 'description') {
            nameA = a.description
            nameB = b.description
          } else if (val2 === 'minProductSalePrice') {
            nameA = a.minProductSalePrice
            nameB = b.minProductSalePrice
          } else if (val2 === 'fbaInvTotalQuantity') {
            nameA = a.fbaInvTotalQuantity
            nameB = b.fbaInvTotalQuantity
          } else if (val2 === 'soldYesterday') {
            nameA = a.soldYesterday
            nameB = b.soldYesterday
          } else if (val2 === 'soldLast7Days') {
            nameA = a.soldLast7Days
            nameB = b.soldLast7Days
          } else if (val2 === 'soldLast30Days') {
            nameA = a.soldLast30Days
            nameB = b.soldLast30Days
          } else if (val2 === 'soldThisMonth') {
            nameA = a.soldThisMonth
            nameB = b.soldThisMonth
          } else if (val2 === 'soldLastMonth') {
            nameA = a.soldLastMonth
            nameB = b.soldLastMonth
          } else if (val2 === 'soldLast60Days') {
            nameA = a.soldLast60Days
            nameB = b.soldLast60Days
          } else if (val2 === 'soldLast90Days') {
            nameA = a.soldLast90Days
            nameB = b.soldLast90Days
          } else if (val2 === 'soldLast180Days') {
            nameA = a.soldLast180Days
            nameB = b.soldLast180Days
          } else if (val2 === 'soldLast365Days') {
            nameA = a.soldLast365Days
            nameB = b.soldLast365Days
          } else if (val2 === 'soldMonthToDate') {
            nameA = a.soldMonthToDate
            nameB = b.soldMonthToDate
          } else if (val2 === 'soldYearToDate') {
            nameA = a.soldYearToDate
            nameB = b.soldYearToDate
          } else if (val2 === 'fbaInvLastUpdatedTime') {
            nameA = a.fbaInvLastUpdatedTime
            nameB = b.fbaInvLastUpdatedTime
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'asin') {
            var nameA = a.asin
            var nameB = b.asin
          } else if (val2 === 'description') {
            nameA = a.description
            nameB = b.description
          } else if (val2 === 'minProductSalePrice') {
            nameA = a.minProductSalePrice
            nameB = b.minProductSalePrice
          } else if (val2 === 'fbaInvTotalQuantity') {
            nameA = a.fbaInvTotalQuantity
            nameB = b.fbaInvTotalQuantity
          } else if (val2 === 'soldYesterday') {
            nameA = a.soldYesterday
            nameB = b.soldYesterday
          } else if (val2 === 'soldLast7Days') {
            nameA = a.soldLast7Days
            nameB = b.soldLast7Days
          } else if (val2 === 'soldLast30Days') {
            nameA = a.soldLast30Days
            nameB = b.soldLast30Days
          } else if (val2 === 'soldThisMonth') {
            nameA = a.soldThisMonth
            nameB = b.soldThisMonth
          } else if (val2 === 'soldLastMonth') {
            nameA = a.soldLastMonth
            nameB = b.soldLastMonth
          } else if (val2 === 'soldLast60Days') {
            nameA = a.soldLast60Days
            nameB = b.soldLast60Days
          } else if (val2 === 'soldLast90Days') {
            nameA = a.soldLast90Days
            nameB = b.soldLast90Days
          } else if (val2 === 'soldLast180Days') {
            nameA = a.soldLast180Days
            nameB = b.soldLast180Days
          } else if (val2 === 'soldLast365Days') {
            nameA = a.soldLast365Days
            nameB = b.soldLast365Days
          } else if (val2 === 'soldMonthToDate') {
            nameA = a.soldMonthToDate
            nameB = b.soldMonthToDate
          } else if (val2 === 'soldYearToDate') {
            nameA = a.soldYearToDate
            nameB = b.soldYearToDate
          } else if (val2 === 'fbaInvLastUpdatedTime') {
            nameA = a.fbaInvLastUpdatedTime
            nameB = b.fbaInvLastUpdatedTime
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
  },
  destroyed() {
  },
  beforeDestroy () {
    this.$root.$off("setNewTabActive");
    this.$root.$off("rowDataObject");
    this.$root.$off("closeZeroPopup");
    this.$root.$off("closeFBAInPopup");
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.xmsmall_col {
  min-width: 50px !important;
  width: 50px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  min-width: 70px !important;
  width: 70px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.loader2 {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #317ED2;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1.2s linear infinite;
}
.val1_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.val_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallC_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.small_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;

}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.mid_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallA_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.Xlarge_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.desc_col {
  min-width: 390px !important;
  width: 390px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}



.centerLine {
  display: flex !important;
  align-items: center !important;
}

</style>
